<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <!-- <div class="model-content">
      <div class="modal-card"> -->
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <!-- <slot name="text-content"></slot> -->
      <div>
        <print-options v-if="innerValue"
          v-show="!isImageAttachActive"
          :sub-quote-nos="innerValue.subQuoteNos"
          v-model="printOptions" />
        <image-select v-if="innerValue"
          v-show="isImageAttachActive"
          v-model="imagesSelectedForPrint"
          :quote-id="innerValue.quoteId"
          :quote-ids="printOptions.quotePrintOptions.selectedQuotes.map(q => q.value)"
          :max-selected="maxImages"
          :subtitle="`Select ${maxImages} images to print`" />
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="ok()"
        data-tooltip="Generate report(s)"
        :disabled="!canPrint">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-printer" />
        </span>
        <span>Ok</span>
      </button>
      <button class="button is-primary tooltip"
        v-show="!attachDisabled"
        @click="attach()"
        :data-tooltip="[!toggleAttachButton ? 'Attach images' : 'Done']"
        :disabled="attachDisabled">
        <span v-if="!toggleAttachButton"
          class="icon is-small">
          <i class="mdi mdi-18px mdi-paperclip" />
        </span>
        <span v-else>Done</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">Cancel</button>
    </footer>
    <!-- </div>
    </div> -->
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { mapGetters, mapActions } from 'vuex'
import PrintOptions from './PrintOptions'
import ImageSelect from '@/components/ImageSelect'
import Guid from '@/components/Guid'
import { Emailer } from '@/classes'
import StoreUtil from '@/store/utils'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { RemarkTypes, AssetTypes, EmailAssetTypes } from '@/enums'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'PrintOptionsModal',
  components: {
    BaseModalEx,
    PrintOptions,
    ImageSelect
  },
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-printer'
    },
    okDisabled: {
      type: Boolean,
      default: false
    },
    attachDisabled: {
      type: Boolean,
      default: true
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    }
    // toggleAttachButton: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      isActive: false,
      innerValue: null,
      isImageAttachActive: false,
      imagesSelectedForPrint: [],
      printOptions: {
        quotePrintOptions: {
          selectedQuotes: [],
          quoteType: 'H',
          itemNumber: true,
          individualItemValue: true,
          partsListPrice: true,
          partPrintType: 'A'
        },
        jobCardPrintOpions: {
          jobCardHourType: 'INDIVIDUAL',
          rrPercent: 100,
          repairPercent: 100,
          paintPercent: 100,
          carInDate: null,
          targetDate: null,
          jobCardReport: false
        },
        jobCoverSheet: false,
        supplementarySheet: false,
        finalCheck: false,
        clearanceAdvice: false,
        clearanceCertificate: false,
        authorityNotification: false
      },
      toggleAttachButton: false
    }
  },
  computed: {
    ...mapGetters(['licenceExpired']),
    canPrint() {
      const enabled =
        this.printOptions.quotePrintOptions.selectedQuotes.length > 0 &&
        (this.printOptions.quotePrintOptions.quoteType ||
          this.printOptions.jobCardPrintOpions.jobCardReport ||
          this.printOptions.jobCoverSheet ||
          this.printOptions.supplementarySheet ||
          this.printOptions.finalCheck ||
          this.printOptions.clearanceAdvice ||
          this.printOptions.clearanceCertificate ||
          this.printOptions.authorityNotification)
      return enabled
    },
    maxImages() {
      return 15
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.innerValue = this.value
    this.printOptions.quotePrintOptions.selectedQuotes = this.value.subQuoteNos
    this.printOptions.quotePrintOptions.quoteType = this.value.quotingMethod
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    ...mapActions('printpreview', { addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' }),
    ok() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('ok')
      this.print()
    },
    attach() {
      this.$emit('show-attach')
      this.toggleAttachButton = !this.toggleAttachButton
      this.isImageAttachActive = this.toggleAttachButton
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('cancel')
      this.imagesSelectedForPrint.splice(0, this.imagesSelectedForPrint.length)
    },
    async print() {
      this.printQuote()
      this.printJobCardReport()
      this.printJobCoverSheetReport()
      this.printSupplementarySheetReport()
      this.printFinalCheckReport()
      this.printClearanceAdviceReport()
      this.printClearanceCertificateReport()
      this.printAuthorityNotification()
    },
    printQuote() {
      if (!this.printOptions.quotePrintOptions.quoteType) {
        return
      }

      const quoteIds = this.printOptions.quotePrintOptions.selectedQuotes
        .map(function(quote) {
          return quote.value
        })
        .join(',')

      let quotes = quoteIds
      let found = quoteIds.includes(this.innerValue.quoteId)
      //check if the selected quote is selected to print
      if (found) {
        quotes = quoteIds.replace(this.innerValue.quoteId, '')
        quotes = quotes.replace(',,', ',')
        quotes = this.innerValue.quoteId + ',' + quotes
      }

      const params = {
        QuoteID: quotes,
        ItemNo: this.printOptions.quotePrintOptions.itemNumber ? 'YES' : 'NONE', // Individual Item Hrs, 'YES' - Selected, 'NONE' - Not Selected
        Rate: this.printOptions.quotePrintOptions.individualItemValue ? 'NONE' : 'YES', // Individual Item Hrs, 'NONE' - Selected, 'YES' - Not Selected
        PartsListPrice: this.printOptions.quotePrintOptions.partsListPrice ? 'NONE' : 'YES', // Parts List Price, 'NONE' - Selected, 'YES' - Not selected
        CustomerID: this.innerValue.customerId,
        InsurerID: this.innerValue.insurerId,
        CompanyID: this.innerValue.companyId,
        Print: this.printOptions.quotePrintOptions.partPrintType, // Print radio buttons, 'A' - AfterMarket, 'P' - Parallel, 'R' - Recycled ORM Parts, 'O' - Parallel OEM Alternate
        ContactID: 'None',
        qiID1: this.getImageIdForPrint(1),
        qiID2: this.getImageIdForPrint(2),
        qiID3: this.getImageIdForPrint(3),
        qiID4: this.getImageIdForPrint(4),
        qiID5: this.getImageIdForPrint(5),
        qiID6: this.getImageIdForPrint(6),
        qiID7: this.getImageIdForPrint(7),
        qiID8: this.getImageIdForPrint(8),
        qiID9: this.getImageIdForPrint(9),
        qiID10: this.getImageIdForPrint(10),
        qiID11: this.getImageIdForPrint(11),
        qiID12: this.getImageIdForPrint(12),
        qiID13: this.getImageIdForPrint(13),
        qiID14: this.getImageIdForPrint(14),
        qiID15: this.getImageIdForPrint(15),
        AssessorID: Guid.empty(),
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }
      // const query = this.$lzstring.compressToEncodedURIComponent(
      //   Object.keys(params)
      //     .map(e => `${e}=${params[e]}`)
      //     .join('&')
      // )
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.innerValue.quoteId
      emailer.assetType = EmailAssetTypes.Quote
      emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      emailer.remark = `Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
      emailer.remarkTypeId = RemarkTypes.OtherSystemRemark
      emailer.subject = `Quote Claim No. ${this.innerValue.claimNo || ''}`
      emailer.reportName = `${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
      this.addEmailer(emailer)
      const hasAdditionalQuotes = this.printOptions.quotePrintOptions.selectedQuotes.find(function(quote) {
        return quote.key > 0
      })

      // Set quote report name
      let reportName = this.printOptions.quotePrintOptions.quoteType === 'H' ? 'rptQuoteDollarHour' : 'rptQuoteDollarOnly'
      if (hasAdditionalQuotes) {
        reportName = `${reportName}Additionals`
      }

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: reportName, quoteIds: this.printOptions.quotePrintOptions.selectedQuotes.map(q => q.value) },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    printJobCardReport() {
      if (this.printOptions.jobCardPrintOpions.jobCardReport) {
        const quoteIds = this.printOptions.quotePrintOptions.selectedQuotes
          .map(function(quote) {
            return quote.value
          })
          .join(',')

        const jobCardParams = {
          QuoteID: quoteIds,
          ItemNo: 'YES',
          Rate: this.printOptions.jobCardPrintOpions.jobCardHourType,
          CustomerID: this.innerValue.customerId,
          RandR: this.printOptions.jobCardPrintOpions.rrPercent,
          Repair: this.printOptions.jobCardPrintOpions.repairPercent,
          Paint: this.printOptions.jobCardPrintOpions.paintPercent,
          InsurerID: this.innerValue.insurerId,
          CompanyID: this.innerValue.companyId,
          timezoneoffset: this.$filters.getSiteTimezoneOffset()
        }
        // const jobCardQuery = this.$lzstring.compressToEncodedURIComponent(
        //   Object.keys(jobCardParams)
        //     .map(e => `${e}=${jobCardParams[e]}`)
        //     .join('&')
        // )
        const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(jobCardParams)
        const parameterId = this.$guid.newGuid()
        StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

        const hasAdditionalQuotes = this.printOptions.quotePrintOptions.selectedQuotes.find(function(quote) {
          return quote.key > 0
        })

        // Set jobcard report name
        let reportName = 'rptQuoteJobCard'
        if (hasAdditionalQuotes) {
          reportName = `${reportName}Additionals`
        }

        const jobCardEmailer = new Emailer()
        jobCardEmailer.assetId = this.innerValue.quoteId
        jobCardEmailer.assetType = EmailAssetTypes.Quote
        jobCardEmailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
        jobCardEmailer.remark = `Job Card for Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        jobCardEmailer.remarkTypeId = RemarkTypes.Remark
        jobCardEmailer.subject = `Job Card for Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        jobCardEmailer.reportName = `JobCard_${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        StoreUtil.setLocalStorage(jobCardEmailer.id, 'emailer', jobCardEmailer)
        const routeData = this.$router.resolve({
          name: PrintPreviewRoutes.PrintPreview.name,
          params: { reportName: reportName },
          query: { parameterId: parameterId, emailerId: jobCardEmailer.id, isNewTab: true }
        })
        window.open(routeData.href, '_blank')
      }
    },
    printJobCoverSheetReport() {
      if (this.printOptions.jobCoverSheet) {
        const jobCoverSheetParams = {
          QuoteID: this.innerValue.quoteId,
          CustomerID: this.innerValue.customerId,
          InsurerID: this.innerValue.insurerId,
          CompanyID: this.innerValue.companyId,
          timezoneoffset: this.$filters.getSiteTimezoneOffset()
        }
        // const jobCoverSheetQuery = this.$lzstring.compressToEncodedURIComponent(
        //   Object.keys(jobCoverSheetParams)
        //     .map(e => `${e}=${jobCoverSheetParams[e]}`)
        //     .join('&')
        // )
        const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(jobCoverSheetParams)
        const parameterId = this.$guid.newGuid()
        StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

        const jobCoverSheetEmailer = new Emailer()
        jobCoverSheetEmailer.assetId = this.innerValue.quoteId
        jobCoverSheetEmailer.assetType = EmailAssetTypes.Quote
        jobCoverSheetEmailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
        jobCoverSheetEmailer.remark = `Job Cover Sheet for Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        jobCoverSheetEmailer.remarkTypeId = RemarkTypes.Remark
        jobCoverSheetEmailer.subject = `Job Cover Sheet for Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        jobCoverSheetEmailer.reportName = `JobCoverSheet_${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        StoreUtil.setLocalStorage(jobCoverSheetEmailer.id, 'emailer', jobCoverSheetEmailer)
        const routeData = this.$router.resolve({
          name: PrintPreviewRoutes.PrintPreview.name,
          params: { reportName: 'rptJobCover' },
          query: { parameterId: parameterId, emailerId: jobCoverSheetEmailer.id, isNewTab: true }
        })
        window.open(routeData.href, '_blank')
      }
    },
    printSupplementarySheetReport() {
      if (this.printOptions.supplementarySheet) {
        const supplementarySheetParams = {
          QuoteID: this.innerValue.quoteId,
          CustomerID: this.innerValue.customerId,
          InsurerID: this.innerValue.insurerId,
          CompanyID: this.innerValue.companyId,
          QuoteType: 'Q'
        }
        // const supplementarySheetQuery = this.$lzstring.compressToEncodedURIComponent(
        //   Object.keys(supplementarySheetParams)
        //     .map(e => `${e}=${supplementarySheetParams[e]}`)
        //     .join('&')
        // )
        const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(supplementarySheetParams)
        const parameterId = this.$guid.newGuid()
        StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

        const supplementarySheetEmailer = new Emailer()
        supplementarySheetEmailer.assetId = this.innerValue.quoteId
        supplementarySheetEmailer.assetType = EmailAssetTypes.Quote
        supplementarySheetEmailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
        supplementarySheetEmailer.remark = `Supplementary Sheet for Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        supplementarySheetEmailer.remarkTypeId = RemarkTypes.Remark
        supplementarySheetEmailer.subject = `Supplementary Sheet for Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        supplementarySheetEmailer.reportName = `SupplementarySheet_${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        StoreUtil.setLocalStorage(supplementarySheetEmailer.id, 'emailer', supplementarySheetEmailer)
        const routeData = this.$router.resolve({
          name: PrintPreviewRoutes.PrintPreview.name,
          params: { reportName: 'rptSupplementary' },
          query: { parameterId: parameterId, emailerId: supplementarySheetEmailer.id, isNewTab: true }
        })
        window.open(routeData.href, '_blank')
      }
    },
    printFinalCheckReport() {
      if (this.printOptions.finalCheck) {
        const finalCheckParams = {
          QuoteID: this.innerValue.quoteId,
          CustomerID: this.innerValue.customerId,
          CompanyID: this.innerValue.companyId
        }
        // const finalCheckQuery = this.$lzstring.compressToEncodedURIComponent(
        //   Object.keys(finalCheckParams)
        //     .map(e => `${e}=${finalCheckParams[e]}`)
        //     .join('&')
        // )
        const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(finalCheckParams)
        const parameterId = this.$guid.newGuid()
        StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

        const finalCheckEmailer = new Emailer()
        finalCheckEmailer.assetId = this.innerValue.quoteId
        finalCheckEmailer.assetType = EmailAssetTypes.Quote
        finalCheckEmailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
        finalCheckEmailer.remark = `Final Check for Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        finalCheckEmailer.remarkTypeId = RemarkTypes.Remark
        finalCheckEmailer.subject = `Final Check for Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        finalCheckEmailer.reportName = `FinalCheck${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        StoreUtil.setLocalStorage(finalCheckEmailer.id, 'emailer', finalCheckEmailer)
        const routeData = this.$router.resolve({
          name: PrintPreviewRoutes.PrintPreview.name,
          params: { reportName: 'rptFinalCheckList' },
          query: { parameterId: parameterId, emailerId: finalCheckEmailer.id, isNewTab: true }
        })
        window.open(routeData.href, '_blank')
      }
    },
    printClearanceAdviceReport() {
      if (this.printOptions.clearanceAdvice) {
        const clearanceAdviceParams = {
          QuoteID: this.innerValue.quoteId,
          InsurerID: this.innerValue.insurerId,
          CompanyID: this.innerValue.companyId
        }
        // const clearanceAdviceQuery = this.$lzstring.compressToEncodedURIComponent(
        //   Object.keys(clearanceAdviceParams)
        //     .map(e => `${e}=${clearanceAdviceParams[e]}`)
        //     .join('&')
        // )
        const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(clearanceAdviceParams)
        const parameterId = this.$guid.newGuid()
        StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

        const clearanceAdviceEmailer = new Emailer()
        clearanceAdviceEmailer.assetId = this.innerValue.quoteId
        clearanceAdviceEmailer.assetType = EmailAssetTypes.Quote
        clearanceAdviceEmailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
        clearanceAdviceEmailer.remark = `Clearance Advice for Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        clearanceAdviceEmailer.remarkTypeId = RemarkTypes.Remark
        clearanceAdviceEmailer.subject = `Clearance Advice for Claim No. ${this.innerValue.claimNo}`
        clearanceAdviceEmailer.reportName = `ClearanceAdvice_${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        StoreUtil.setLocalStorage(clearanceAdviceEmailer.id, 'emailer', clearanceAdviceEmailer)
        const routeData = this.$router.resolve({
          name: PrintPreviewRoutes.PrintPreview.name,
          params: { reportName: 'rptClearanceAdvice' },
          query: { parameterId: parameterId, emailerId: clearanceAdviceEmailer.id, isNewTab: true }
        })
        window.open(routeData.href, '_blank')
      }
    },
    printClearanceCertificateReport() {
      if (this.printOptions.clearanceCertificate) {
        const clearanceCertificateParams = {
          QuoteID: this.innerValue.quoteId,
          CustomerID: this.innerValue.customerId,
          CompanyID: this.innerValue.companyId
        }

        const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(clearanceCertificateParams)
        const parameterId = this.$guid.newGuid()
        StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

        const clearanceCertificateEmailer = new Emailer()
        clearanceCertificateEmailer.assetId = this.innerValue.quoteId
        clearanceCertificateEmailer.assetType = EmailAssetTypes.Quote
        clearanceCertificateEmailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
        clearanceCertificateEmailer.remark = `Clearance Certificate for Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        clearanceCertificateEmailer.remarkTypeId = RemarkTypes.Remark
        clearanceCertificateEmailer.subject = `Clearance Certificate for Claim No. ${this.innerValue.claimNo}`
        clearanceCertificateEmailer.reportName = `ClearanceCertificate_${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        StoreUtil.setLocalStorage(clearanceCertificateEmailer.id, 'emailer', clearanceCertificateEmailer)
        const routeData = this.$router.resolve({
          name: PrintPreviewRoutes.PrintPreview.name,
          params: { reportName: 'rptClearanceCertificate' },
          query: { parameterId: parameterId, emailerId: clearanceCertificateEmailer.id, isNewTab: true }
        })
        window.open(routeData.href, '_blank')
      }
    },
    printAuthorityNotification() {
      if (this.printOptions.authorityNotification) {
        const authorityNotificationParams = {
          QuoteID: this.innerValue.quoteId,
          CustomerID: this.innerValue.customerId,
          CompanyID: this.innerValue.companyId
        }

        const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(authorityNotificationParams)
        const parameterId = this.$guid.newGuid()
        StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

        const authorityNotificationEmailer = new Emailer()
        authorityNotificationEmailer.assetId = this.innerValue.quoteId
        authorityNotificationEmailer.assetType = EmailAssetTypes.Quote
        authorityNotificationEmailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
        authorityNotificationEmailer.remark = `Customer Repair & Payment Authority Notification for Quote ${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        authorityNotificationEmailer.remarkTypeId = RemarkTypes.Remark
        authorityNotificationEmailer.subject = `Customer Repair & Payment Authority Notification for Claim No. ${this.innerValue.claimNo}`
        authorityNotificationEmailer.reportName = `AuthorityNotification_${this.innerValue.prefix}${this.innerValue.quoteNo}${this.innerValue.suffix}`
        StoreUtil.setLocalStorage(authorityNotificationEmailer.id, 'emailer', authorityNotificationEmailer)
        const routeData = this.$router.resolve({
          name: PrintPreviewRoutes.PrintPreview.name,
          params: { reportName: 'rptAuthorityNotification' },
          query: { parameterId: parameterId, emailerId: authorityNotificationEmailer.id, isNewTab: true }
        })
        window.open(routeData.href, '_blank')
      }
    },
    getImageIdForPrint(index) {
      if (index <= this.imagesSelectedForPrint.length) {
        return this.imagesSelectedForPrint[index - 1].quoteImageId
      } else {
        return Guid.empty()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
