<template>
  <div>
    <div class="tile is-ancestor">
      <div class="tile">
        <div class="tile is-parent">
          <article class="tile is-child box">
            <h5 class="title is-5">Quote Report options</h5>
            <div class="field">
              <span v-for="quote in subQuoteNos"
                :key="quote.key">
                <div class="pretty p-icon p-curve p-smooth p-bigger">
                  <input :id="`quote${quote.key}`"
                    type="checkbox"
                    :value="quote"
                    v-model="innerValue.quotePrintOptions.selectedQuotes"
                    checked="checked">
                  <div class="state p-primary">
                    <i class="icon mdi mdi-check" />
                    <label>{{ quote.key === 0 ? 'Quote' : `Add ${quote.key}` }}</label>
                  </div>
                </div>
                <!-- <input class="is-checkradio"
                  :id="`quote${quote.key}`"
                  type="checkbox"
                  :name="`quote${quote.key}`"
                  :value="quote"
                  v-model="innerValue.quotePrintOptions.selectedQuotes"
                  checked="checked">
                <label :for="`quote${quote.key}`">{{ quote.key === 0 ? 'Quote' : `Add ${quote.key}` }}</label> -->
              </span>
            </div>
            <div class="is-divider" />
            <!-- <div class="title is-6">Additional quote report options</div> -->
            <div class="field">

              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="hoursDollarQuote"
                  type="checkbox"
                  value="H"
                  v-model="quoteTypes.H"
                  @click="changeReportType($event)">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Hours Dollar Quote</label>
                </div>
              </div>
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="dollarOnlyQuote"
                  type="checkbox"
                  value="D"
                  v-model="quoteTypes.D"
                  @click="changeReportType($event)">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Dollar Only Quote</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="hoursDollarQuote"
                type="checkbox"
                name="hoursDollarQuote"
                value="H"
                v-model="quoteTypes.H"
                @click="changeReportType($event)">
              <label for="hoursDollarQuote">Hours Dollar Quote</label>
              <input class="is-checkradio"
                id="dollarOnlyQuote"
                type="checkbox"
                name="dollarOnlyQuote"
                value="D"
                v-model="quoteTypes.D"
                @click="changeReportType($event)">
              <label for="dollarOnlyQuote">Dollar Only Quote</label> -->
            </div>
            <div class="is-divider" />
            <div class="field">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="itemNumber"
                  type="checkbox"
                  v-model="innerValue.quotePrintOptions.itemNumber">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Item Number</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="itemNumber"
                type="checkbox"
                checked="checked"
                name="itemNumber"
                v-model="innerValue.quotePrintOptions.itemNumber">
              <label for="itemNumber">Item Number</label> -->
            </div>
            <div class="field">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="individualItemHours"
                  type="checkbox"
                  v-model="innerValue.quotePrintOptions.individualItemValue">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Individual Item {{ innerValue.quotePrintOptions.quoteType === 'H' ? 'Hours' : '$' }}</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="individualItemHours"
                type="checkbox"
                checked="checked"
                name="individualItemHours"
                v-model="innerValue.quotePrintOptions.individualItemValue">
              <label for="individualItemHours">Individual Item {{ innerValue.quotePrintOptions.quoteType === 0 ? 'Hours' : '$' }}</label> -->
            </div>
            <div class="field">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="partsListPrice"
                  type="checkbox"
                  v-model="innerValue.quotePrintOptions.partsListPrice">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Parts List Price</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="partsListPrice"
                type="checkbox"
                checked="checked"
                name="partsListPrice"
                v-model="innerValue.quotePrintOptions.partsListPrice">
              <label for="partsListPrice">Parts List Price</label> -->
            </div>
            <div class="field">
              <div class="pretty p-default p-round p-bigger p-smooth">
                <input type="radio"
                  name="afterMarket"
                  :value="'A'"
                  v-model="innerValue.quotePrintOptions.partPrintType">
                <div class="state p-primary-o">
                  <label>Print "After Market"</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="afterMarket"
                type="radio"
                value="A"
                v-model="innerValue.quotePrintOptions.partPrintType"
                name="afterMarket">
              <label for="afterMarket">Print "After Market"</label> -->
            </div>
            <div class="field">
              <div class="pretty p-default p-round p-bigger p-smooth">
                <input type="radio"
                  name="parallel"
                  :value="'P'"
                  v-model="innerValue.quotePrintOptions.partPrintType">
                <div class="state p-primary-o">
                  <label>Print "Parallel"</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="parallel"
                type="radio"
                value="P"
                v-model="innerValue.quotePrintOptions.partPrintType"
                name="parallel">
              <label for="parallel">Print "Parallel"</label> -->
            </div>
            <div class="field">
              <div class="pretty p-default p-round p-bigger p-smooth">
                <input type="radio"
                  name="recycledOemParts"
                  :value="'R'"
                  v-model="innerValue.quotePrintOptions.partPrintType">
                <div class="state p-primary-o">
                  <label>Print "Recycled OEM Parts"</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="recycledOemParts"
                type="radio"
                value="R"
                v-model="innerValue.quotePrintOptions.partPrintType"
                name="recycledOemParts">
              <label for="recycledOemParts">Print "Recycled OEM Parts"</label> -->
            </div>
            <div class="field">
              <div class="pretty p-default p-round p-bigger p-smooth">
                <input type="radio"
                  name="parallelOemParts"
                  :value="'O'"
                  v-model="innerValue.quotePrintOptions.partPrintType">
                <div class="state p-primary-o">
                  <label>Print "Parallel OEM Parts"</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="parallelOemParts"
                type="radio"
                value="O"
                v-model="innerValue.quotePrintOptions.partPrintType"
                name="parallelOemParts">
              <label for="parallelOemParts">Print "Parallel OEM Parts"</label> -->
            </div>
          </article>
        </div>
        <div class="tile is-parent is-vertical">
          <article class="tile is-child box">
            <h5 class="title is-5">Select additional reports to print</h5>
            <!-- <div class="is-divider"></div> -->
            <div class="field">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="jobCard"
                  type="checkbox"
                  v-model="innerValue.jobCardPrintOpions.jobCardReport">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Job Card</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="jobCard"
                type="checkbox"
                name="jobCard"
                v-model="innerValue.jobCardPrintOpions.jobCardReport">
              <label for="jobCard">Job Card</label> -->
            </div>
            <div v-if="innerValue.jobCardPrintOpions.jobCardReport"
              class="is-divider" />
            <div v-if="innerValue.jobCardPrintOpions.jobCardReport"
              class="title is-6">Job Card report options</div>
            <div v-if="innerValue.jobCardPrintOpions.jobCardReport"
              class="columns is-gapless">
              <div class="column is-narrow">
                <div class="field">
                  <div class="pretty p-default p-round p-bigger p-smooth">
                    <input type="radio"
                      id="categoryTargetHours"
                      value="CAT"
                      v-model="innerValue.jobCardPrintOpions.jobCardHourType">
                    <div class="state p-primary-o">
                      <label>Category Target Hours</label>
                    </div>
                  </div>
                  <!-- <input class="is-checkradio"
                    id="categoryTargetHours"
                    type="radio"
                    value="CAT"
                    v-model="innerValue.jobCardPrintOpions.jobCardHourType"
                    name="categoryTargetHours">
                  <label for="categoryTargetHours">Category Target Hours</label> -->
                </div>
                <div class="field">
                  <div class="pretty p-default p-round p-bigger p-smooth">
                    <input type="radio"
                      id="jobCardIndividualItemHours"
                      value="INDIVIDUAL"
                      v-model="innerValue.jobCardPrintOpions.jobCardHourType">
                    <div class="state p-primary-o">
                      <label>Individual Item Hours</label>
                    </div>
                  </div>
                  <!-- <input class="is-checkradio"
                    id="jobCardIndividualItemHours"
                    type="radio"
                    value="INDIVIDUAL"
                    v-model="innerValue.jobCardPrintOpions.jobCardHourType"
                    name="jobCardIndividualItemHours">
                  <label for="jobCardIndividualItemHours">Individual Item Hours</label> -->
                </div>
                <div class="field">
                  <div class="pretty p-default p-round p-bigger p-smooth">
                    <input type="radio"
                      id="categoryTargetHours"
                      value="PRINT"
                      v-model="innerValue.jobCardPrintOpions.jobCardHourType">
                    <div class="state p-primary-o">
                      <label>Do not print Hours</label>
                    </div>
                  </div>
                  <!-- <input class="is-checkradio"
                    id="doNotPrintHours"
                    type="radio"
                    value="PRINT"
                    v-model="innerValue.jobCardPrintOpions.jobCardHourType"
                    name="doNotPrintHours">
                  <label for="doNotPrintHours">Do not print Hours</label> -->
                </div>
              </div>
              <div class="column is-narrow">
                <div class="field">
                  <div class="control is-narrow">
                    <label class="label">R &amp; R %</label>
                    <vue-numeric class="input has-text-right"
                      v-model="innerValue.jobCardPrintOpions.rrPercent"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="R &amp; R %"
                      symbol-position="suffix"
                      :precision="0" />
                  </div>
                </div>
                <div class="field">
                  <label class="label">Repair %</label>
                  <div class="control is-narrow">
                    <vue-numeric class="input has-text-right"
                      v-model="innerValue.jobCardPrintOpions.repairPercent"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Repair %"
                      symbol-position="suffix"
                      :precision="0" />
                  </div>
                </div>
                <div class="field">
                  <label class="label">Paint %</label>
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="innerValue.jobCardPrintOpions.paintPercent"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Paint %"
                      symbol-position="suffix"
                      :precision="0" />
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="innerValue.jobCoverSheet"
                  type="checkbox"
                  v-model="innerValue.jobCoverSheet">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Job Cover Sheet</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="innerValue.jobCoverSheet"
                type="checkbox"
                name="innerValue.jobCoverSheet"
                v-model="innerValue.jobCoverSheet">
              <label for="innerValue.jobCoverSheet">Job Cover Sheet</label> -->
            </div>
            <div class="field">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="innerValue.supplementarySheet"
                  type="checkbox"
                  v-model="innerValue.supplementarySheet">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Supplementary Sheet</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="innerValue.supplementarySheet"
                type="checkbox"
                name="innerValue.supplementarySheet"
                v-model="innerValue.supplementarySheet">
              <label for="innerValue.supplementarySheet">Supplementary Sheet</label> -->
            </div>
            <div class="field">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="innerValue.finalCheck"
                  type="checkbox"
                  v-model="innerValue.finalCheck">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Final Check</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="innerValue.finalCheck"
                type="checkbox"
                name="innerValue.finalCheck"
                v-model="innerValue.finalCheck">
              <label for="innerValue.finalCheck">Final Check</label> -->
            </div>
            <div class="field">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="innerValue.clearanceAdvice"
                  type="checkbox"
                  v-model="innerValue.clearanceAdvice">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Clearance Advice</label>
                </div>
              </div>
              <!-- <input class="is-checkradio"
                id="innerValue.clearanceAdvice"
                type="checkbox"
                name="innerValue.clearanceAdvice"
                v-model="innerValue.clearanceAdvice">
              <label for="innerValue.clearanceAdvice">Clearance Advice</label> -->
            </div>
            <div class="field">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="innerValue.clearanceCertificate"
                  type="checkbox"
                  v-model="innerValue.clearanceCertificate">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Clearance Certificate</label>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="innerValue.authorityNotification"
                  type="checkbox"
                  v-model="innerValue.authorityNotification">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Customer Repair &amp; Payment Authority Notification</label>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueNumeric from '@/components/VueNumeric'
// import MaskedInput from 'vue-masked-input'

export default {
  name: 'PrintOptions',
  components: {
    VueNumeric
    // MaskedInput
  },
  props: {
    subQuoteNos: {
      type: Array,
      default: function () {
        return []
      }
    },
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      innerValue: this.value,
      // isHourDollar: this.value.quotePrintOptions.quoteType === 'H',
      // isDollarOnly: this.value.quotePrintOptions.quoteType === 'D',
      quoteTypes: {
        H: this.value.quotePrintOptions.quoteType === 'H',
        D: this.value.quotePrintOptions.quoteType === 'D'
      }
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
    // isHourDollar(newVal, oldVal) {
    //   if (newVal) {
    //     this.innerValue.quotePrintOptions.quoteType = 'H'
    //     if (this.isDollarOnly) {
    //       this.isDollarOnly = false
    //     }
    //   } else if (!this.isDollarOnly) {
    //     this.innerValue.quotePrintOptions.quoteType = ''
    //   }
    // },
    // isDollarOnly(newVal, oldVal) {
    //   if (newVal) {
    //     this.innerValue.quotePrintOptions.quoteType = 'D'
    //     if (this.isHourDollar) {
    //       this.isHourDollar = false
    //     }
    //   } else if (!this.isHourDollar) {
    //     this.innerValue.quotePrintOptions.quoteType = ''
    //   }
    // }
  },
  created() {
    // this.innerValue
  },
  methods: {
    changeReportType(event) {
      this.quoteTypes.H = false
      this.quoteTypes.D = false
      this.quoteTypes[event.target.value] = event.target.checked
      if (this.quoteTypes.H) {
        this.innerValue.quotePrintOptions.quoteType = 'H'
      } else if (this.quoteTypes.D) {
        this.innerValue.quotePrintOptions.quoteType = 'D'
      } else {
        this.innerValue.quotePrintOptions.quoteType = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
